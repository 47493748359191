/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p"
  }, _provideComponents(), props.components);
  return React.createElement(_components.p, null, "Al final me he decidido a escribir un blog así lo utilizaré como recordatorio de las cosas que hago y también claro para que lo vea quien quiera. El caso es que el día 1 de Enero para celebrar el nuevo año me puse a instalar debian antes tenia ubuntu pero no me acababa de convencer así que con la emoción de la nueva instalación me propuse escribir este blog y aquí estoy empezando hoy.");
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
